import AndroidButton from '@/components/AndroidButton'
import AlertG from '@/components/alertG/AlertG'

export default {
  name: 'BuyTicket',
  components: {
    AndroidButton,
    AlertG
  },
  data () {
    return {
      reloadListOnClose:false,
      top:'100%',
      opacity:0,
      transition:"top 0.1s linear ,opacity 0.3s ease-in-out",
      infos:{heureDepart:'0'},
      toPay:1
    }
  },
  emits:["inscription","reloadList","buy"],
  methods: {
    close () {
      this.top = "100%"
      this.opacity = 0
      this.transition = "top 0.3s linear ,opacity 0.1s ease-in-out"
      if(this.reloadListOnClose) this.$emit('reloadList')
    },
    show (infos) {
      this.top = "0%"
      this.opacity = 1
      this.transition="top 0.05s ease-in ,opacity 0.2s ease-out"
      //------------
      this.infos = infos
      this.toPay = 1
      this.reloadListOnClose = false
    },
    move (i) {
      let result = this.toPay + i
      let restant = this.infos.nbrTicket - this.infos.ticketVendu
      this.toPay = result < 1 || result > restant ? this.toPay : result
    },
    buying () {
      if(this.$store.state.user.connected) {
        let toSend = {
          type:'buyTicket',
          toPay: this.toPay,
          voyage: this.infos.id,
          userId:this.$store.state.user.id,
          userName:this.$store.state.user.name,
          agency: this.$store.state.currentAgency.nom
        }
        this.$emit('buy',{toSend:toSend,vendu:this.infos.ticketVendu})
      }
      else this.$emit('inscription')
    },
    ending (vendu) {
      this.reloadListOnClose = true
      this.infos.ticketVendu = vendu
    }
  }
}
