export default {
  name: 'conditions',
  data () {
    return {
      Display: false
    }
  },
  methods: {
    show() {
      this.Display = true
    },
    clicked () {
      this.Display=false
    }
  }
}
