<template>
    <div :style="{top:top,opacity:opacity,transition:transition}" class="buyTicket">
        <div class="buyTicketHead">
            <div class="buyTicketHL">
                <span>DE</span>
                <div>{{ infos.depart }}</div>
            </div>            
            <div class="buyTicketHR">
                <span>À</span>
                <div>{{ infos.arrivee }}</div>
            </div>
            <div class="buyTicketHBus"><img src="./img/bus2.svg"/></div>
            <AndroidButton @clicked="close" radius="2em" class="buyTicketHCross"><img src="./img/cross.svg"/></AndroidButton>
        </div>
        <div class="busTicketInfo">
            <div class="buyTicketIL">
                <span>DATE DE DEPART</span>
                <div>{{ infos.jour+' '+this.$store.state.mois[infos.mois-1]+' '+infos.annee+' à '+infos.heureDepart.toUpperCase() }}</div>
            </div>            
            <div class="buyTicketIR">
                <span>CLASSE</span>
                <div>{{ infos.classe }}</div>
                <span>DEPART A</span>
                <div>{{ infos.lieu }}</div>
            </div>
        </div>
        <div class="buyTicketSecond">
            <div class="buyTicketNumb">
                <div class="buyTicketNumbt">Total à payer</div>
                <div>{{ toPay*infos.prixTicket }} fcfa</div>
            </div>
            <div class="buyTicketNumb">
                <div class="buyTicketNumbt">Nombre de ticket</div>
                <div>
                    <span @click="move(-1)">-</span>
                        <div>{{ toPay }}</div>
                    <span @click="move(1)">+</span>
                </div>
            </div>
            <div class="buyTicketButton">
                <AndroidButton @clicked="buying" radius="0.2em">Acheter maintenant</AndroidButton>
            </div>
        </div>
    <AlertG ref="AlertG"/>
    <AlertG ref="AlertG1" @okay="close"/>
    </div>
</template>
<style src="./buyTicket.css">
</style>
<script src = "./buyTicket.js">
</script>
