<template>
  <!-- <router-view/> -->
  <router-view v-slot="{ Component }">
  <transition name="fade" mode="out-in">
    <component :is="Component" />
  </transition>
</router-view>
  <Footer style="z-index:15"/>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

:root {
  --mainColor: rgb(60, 94, 189);
}

#app {
  font-family:FFMarkPro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color:#37404A;
  font-size: 15px;
}

/*
@font-face {
  font-family: 'FFMarkHairline';
  src: url('~@/fonts/FontFont_FF.Mark.Pro.Hairline.otf');
}
@font-face {
    font-family: 'FFMarkProBlack';
    src: url('~@/fonts/FontFont_FF.Mark.Pro.Black.otf');
}
@font-face {
    font-family: 'FFMarkProMedium';
    src: url('~@/fonts/FontFont_FF.Mark.Pro.Medium.otf');
}*/
@font-face {
    font-family: 'FFMarkPro';
    src: url('~@/fonts/FontFont_FF.Mark.Pro.otf');
}


#app * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

</style>

<script>
  import Footer from './components/lFooter/LFooter.vue'
  export default {
  name: 'App',
  components: {
    Footer
  },
  mounted () {
        function getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    if(getCookie("userId") != "" && !this.$store.state.user.connected) {
      let user = {id:getCookie("userId"),name:getCookie("userName"),tel:getCookie("userTel"),connected:true}
      this.$store.commit('connexion',user)
    }
  }
}

</script>