export default {
  name: 'alertG',
  props: {
    display:  Boolean
  },
  data () {
    return {
      Display: false,
      text:`Erreur la transaction a <b>échoué</b> pour l'une des raison suivante:<br> 
      - Vous a été annulé la transaction.<br>
      - la transaction n'a pas été validée dans les temps et ne peut plus l'etre, veuillez réessayer.<br>
      - Vous n'avez pas suffisament d'argent sur votre compte.<br> 
      - Limite de payement atteinte.<br>
      - Compte non autorsisé.<br>
      `
    }
  },
  emits:['okay'],
  methods: {
    displaying(text) {
      this.text = text
      this.Display = true
    },
    clicked () {
      this.Display=false
      this.$emit('okay')
    }
  }
}
