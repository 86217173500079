import Home from './button/Home' 
import Ticket from './button/Ticket'
import Bus from './button/Bus'
import User from './button/User'
import Service from './button/Service'
import AndroidButton from '@/components/AndroidButton'

export default {
  name: 'lFooter',
  components: {
    Home,
    Ticket,
    Bus,
    User,
    Service,
    AndroidButton
  },
  data () {
    return {
      elts: {home:true,reserve:false,tickets:false,compte:false,contact:false},
      current: 'home',
      Display:false
    }
  },
  methods: {
    change (elt) {
      this.$router.push(elt)
      if(this.$route.name !=elt) this.$router.push(elt)
      if(this.$route.name !=elt) this.$router.push(elt)
      
    },
    change1 (elt) {
      if(!this.elts[elt] && elt != 'start') {
        this.elts[elt] = true
        this.elts[this.current] = false
        this.current = elt
      }
    },
  },
  watch: {
    '$route' (to/*, from*/) {

      if(to.name == 'home') this.Display = true // to prevent the footer to be displayed on launch
      // react to route changes...
      this.change1(to.name)
    }
  }
}
