<template>
    <div v-show="Display" class="confirm">
        <div class="confirmBox">
            <div class="confirmText" v-html="text"></div>
            <div class="confirmbutton">
                <div @click="clicked('yes')" >oui</div>
                <div @click="clicked('no')" style="background-color:rgb(0, 81, 255);" >non</div>
            </div>
        </div>
    </div>
</template>
<style src="./confirm.css">
</style>
<script src = "./confirm.js">
</script>
