import LFooter from '@/components/lFooter/LFooter'
export default {
    name: 'Contact',
  components: {
    LFooter
  },
  data () {
    return {
      email:'',
      nom:'',
      message:'',
      phone:''
    }
  },
  methods: {
    send () {
      this.axios.post("http://localhost:5000/"/*this.$store.state.baseUrl*/, {
        email:this.email,
        nom:this.nom,
        message:this.message,
        phone:this.phone
      })
        .then(({data}) => {
          alert(data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  beforeRouteEnter(to, from) {
    if(typeof from.name == 'undefined') return {name:'start'}
  }
}