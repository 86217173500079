<template>
  <div class="contactBody">
      <h3> Contactez {{ $store.state.currentAgency.nom }}</h3>
      <div><b>Email</b> {{ $store.state.currentAgency.email == '' ? 'Pas d\'email disponible':$store.state.currentAgency.email }}</div>
      <div><b>Téléphone </b>{{ $store.state.currentAgency.tel }}</div>
      <p class="contactDev">
        <span>Développé par <b>estadiotamo@gmail.com</b></span>
      </p>
  </div>
</template>
<style src="./contact.css">
</style>
<script src = "./contact.js">
</script>