import { createStore } from 'vuex'

export default createStore({
  state: {
    //baseUrl: 'http://localhost:3000/',
    //baseUrl: 'http://192.168.43.251:3000/',
    baseUrl:'https://dzoula.com/',
    trajets:{loaded:false,elts:[]},
    //busClass:[{name:'classic',color:'gray'},{name:'VIP',color:'gold'}],
    classColor:{classic:'rgba(128, 128, 128, 0.7)',VIP:'rgba(240, 200, 0, 0.7)'},
    user:{id:-1,name:'',tel:0,connected:false},
    mois: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet',
        'Aout','Septembre','Octobre','Novembre','Décembre'],
    agencies:{loaded:false,elts:[]},
    currentAgency:{nom:'AgencyName'}
  },
  mutations: {
    setTrajet(state,playload) {
      state.trajets.loaded = true
      state.trajets.elts = playload
    },
    connexion (state,playload) {
      state.user.connected = playload.connected
      state.user.id = playload.id
      state.user.name = playload.name
      state.user.tel = playload.tel
    },
    deconnexion (state) {
      state.user.connected = false
    },
    setAgencies(state,playload) {
      state.agencies.loaded = true
      state.agencies.elts = playload
    },
    setCurrentAgency(state,playload) {
      state.currentAgency = playload
      state.trajets = {loaded:false,elts:[]}
    }
  },
  actions: {
  },
  modules: {
  }
})
