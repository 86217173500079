<template>
    <AndroidButton @clicked="$emit('clicked')">
      <svg
         class="footerService"
         :fill="selected ? 'rgba(63, 69, 146)':'black'"
         height="761.03644"
         width="800"
         version="1.1"
         id="Capa_1"
         viewBox="0 0 361.014 343.43101"
         xml:space="preserve"
         sodipodi:docname="customer-service-headset-svgrepo-com.svg"
         inkscape:version="1.1 (c4e8f9e, 2021-05-24)"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:svg="http://www.w3.org/2000/svg"><defs
         id="defs10" />
         <g
         id="g5"
         transform="translate(0,-8.791)">
         <g
         id="Layer_5_47_">
         <path
         d="M 331.035,126.94 H 318.01 c -3.563,0 -3.682,-2.333 -3.805,-3.494 C 307.375,59.094 252.77,8.791 186.637,8.791 h -12.26 c -65.644,0 -119.929,49.56 -127.409,113.229 -0.191,1.631 -0.291,4.92 -3.291,4.92 H 29.978 C 20.987,126.94 0,136.401 0,184.18 v 25.075 c 0,35.436 20.987,43.609 29.978,43.609 h 43.584 c 8.991,0 16.347,-7.356 16.347,-16.347 v -93.23 c 0,-8.991 -7.356,-16.347 -16.347,-16.347 0,0 -2.052,-0.18 -1.529,-3.835 7.192,-50.319 50.129,-89.313 102.344,-89.313 h 12.26 c 51.86,0 94.912,38.418 102.2,88.288 0.235,1.608 1.111,4.86 -1.385,4.86 -8.991,0 -16.347,7.356 -16.347,16.347 v 93.23 c 0,8.991 7.356,16.347 16.347,16.347 h 8.184 c 2.25,0 1.868,1.798 1.667,2.704 -6.667,30.104 -21.637,64.256 -55.238,64.256 h -24.889 c -2.54,0 -3.167,-1.861 -3.65,-2.743 -4.032,-7.367 -11.851,-12.364 -20.841,-12.364 h -22.933 c -13.118,0 -23.753,10.634 -23.753,23.753 0,13.119 10.635,23.752 23.753,23.752 h 22.933 c 9.112,0 17.023,-5.132 21.005,-12.662 0.348,-0.658 0.633,-2.026 3.321,-2.026 h 25.054 c 22.823,0 53.365,-11.341 69.259,-65.373 1.694,-5.758 3.068,-11.496 4.187,-17.026 0.154,-0.761 0.25,-2.27 2.625,-2.27 h 12.9 c 8.991,0 29.978,-8.174 29.978,-43.609 V 184.181 C 361.013,137.082 340.026,126.94 331.035,126.94 Z"
         id="path2" />
         </g>
         </g>
      </svg>
    </AndroidButton>
</template>
<style>
.footerService {
    width: 27px;
    height: 27px;
}
</style>
<script>
import AndroidButton from '@/components/AndroidButton'
  
  export default {
  name: 'service',
  props: {
      selected: {
          type: Boolean,
          default:false
      }
  },
  emits:["clicked"],
  components: {
    AndroidButton      
  }
}
</script>
