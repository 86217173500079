<template>
    <div v-show="Display" class="conditions">
        <div class="conditionsBox">
            <div class="conditionsCont">
                <h3>CONDITION GENERAL D’UTILISATION</h3>
                <h4>1- PROPRIETE INTELLECTUELLE</h4>
                Tous contenus, logos, images et texte présent sur DZOULA sont protégés par les lois sur la propriété intellectuelle. Ils ne peuvent être utilisés, copié ou reproduits sans autorisation préalable.
                <h4>2- RENSEIGNEMENT SUR LA FACTURATION</h4>
                Les tarifs des billets sont indiqués clairement sur l’application. Tout paiement effectué est non remboursable, sauf en cas d’annulation par le transporteur. 
                <h4>3- ABONNEMENT ET COMPTES </h4>
                Les utilisateurs peuvent créer un compte pour gérer leurs réservations. Ils sont responsables de la confidentialité de leurs informations de connexion. 
                <h4>4- UTILISATION ACCEPTABLE DE L’APPLICATION</h4>
                Les visiteurs s’engagent à n’utiliser l’application que dans un but légal et à respecter les lois en vigueur.
                <h4>5- ACTIVITE INTERDITES</h4>
                Il est interdit de: 
                    • Utiliser l’application à des fins frauduleuses;
                    • Copier, modifier ou distribuer le contenu sans autorisation; 
                    • Perturber le fonctionnement de l’application. 
                <h4>6- GARANTIES ET AVIS DE NON RESPONSABILITE</h4>
                L’application ne garantit pas l’exactitude des informations fournies. Les utilisateurs sont responsables de vérifier les détails de réservation.
                <h4>7- CONDITION VENTE</h4>
                Les conditions spécifiques à la vente de billet de bus sont disponibles lors du processus de réservation.

            </div>
            <div class="conditionsbutton"><div @click="clicked" >OK</div></div>
        </div>
    </div>
</template>
<style src="./conditions.css">
</style>
<script src = "./conditions.js">
</script>
