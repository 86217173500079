<template>
    <div class="androidButton" @click="clicked(this)" :style="{borderRadius:radius}">
      <slot></slot> 
      <div class="androidButton1" :style="{borderRadius:radius,animation:blink}"></div>
    </div>
</template>
<style>
@keyframes blink {
  from {
    background-color: rgba(128, 128, 128, 0.5);
  }
  to {
    background-color: transparent;
  }
}
.androidButton {
    position: relative;
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius:1em ;
    display: flex;justify-content: center;align-items: center;
    cursor:pointer;
}
.androidButton1 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius:1em ;
}

</style>
<script>
  export default {
  name: 'AndroidButton',
  props: {
    radius: {
      type:String,
      default:'1em'
    }
  },
  data () {
      return {
          blink:false
      }
  },
  emits:["clicked"],
  methods: {
      clicked: async (k)=>  {
        function waitDelay(t){
            return new Promise((resolve) => {
                setTimeout(resolve, t);
            })
        }
        k.blink = 'none'
        await waitDelay(50)
        k.blink = 'blink 0.5s 1 ease-out'
        await waitDelay(370)
        k.$emit('clicked')
      }
  }
}
</script>
