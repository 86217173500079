<template>
    <AndroidButton @clicked="$emit('clicked')">
        <svg  class="footerBus" width="800px" height="800px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Dribbble-Light-Preview" transform="translate(-180.000000, -5479.000000)" fill="#000000">
                <g id="icons" transform="translate(56.000000, 160.000000)">
                    <path :fill="selected ? 'rgba(63, 69, 146)':'black'" d="M126,5329 L126,5322.104 C126,5321.552 126.448,5321 127,5321 L141,5321 C141.552,5321 142,5321.552 142,5322.104 L142,5329 L126,5329 Z M128,5335.104 L128,5335.104 C127.448,5335.104 127,5334.656 127,5334.104 C127,5333.552 127.448,5333.104 128,5333.104 C128.552,5333.104 129,5333.552 129,5334.104 C129,5334.656 128.552,5335.104 128,5335.104 L128,5335.104 Z M140,5335.104 L140,5335.104 C139.448,5335.104 139,5334.656 139,5334.104 C139,5333.552 139.448,5333.104 140,5333.104 C140.552,5333.104 141,5333.552 141,5334.104 C141,5334.656 140.552,5335.104 140,5335.104 L140,5335.104 Z M126,5319 C124.895,5319 124,5320 124,5321.104 L124,5329.104 L124,5335.104 C124,5336.209 124.895,5337.104 126,5337.104 L126,5338.104 C126,5338.656 126.448,5339 127,5339 L129,5339 C129.552,5339 130,5338.656 130,5338.104 L130,5337 L138,5337 L138,5338.104 C138,5338.656 138.448,5339 139,5339 L141,5339 C141.552,5339 142,5338.656 142,5338.104 L142,5337.104 C143.105,5337.104 144,5336.209 144,5335.104 L144,5329.104 L144,5321.104 C144,5320 143.105,5319 142,5319 L126,5319 Z" id="bus_front_view-[#615]">

            </path>
                </g>
            </g>
            </g>
        </svg>


    </AndroidButton>
</template>
<style>
.footerBus {
    width: 25px;
    height: 27px;
}
</style>
<script>
import AndroidButton from '@/components/AndroidButton'
  
  export default {
  name: 'bus',
  props: {
      selected: {
          type: Boolean,
          default:false
      }
  },
  emits:["clicked"],
  components: {
    AndroidButton      
  }
}
</script>
