<template>
<section v-show="Display" @click="closing" ref='main' class="inscription1">
    <form class = "insMainSection1" @submit="submiting" action="#">
        <div class = "insTitle1">
            <span>Connexion</span>
            <div v-show="DispErr" class="insError1">{{error}}</div>
        </div>
        <div class ="insInput1">
            <InputI0 placeholder="Entrez num téléphone" ref="contact" type = "number" />
        </div>
        <div class ="insInput1">
            <InputI0 placeholder="Entrez un mot de passe" ref="password" type = "password" pattern="^.{4,22}$" />
        </div>
        <div class="insLaw1">
            <span class ="insPolicy1">Vous avez oublié votre mot de passe? 
                <span @click="$refs.alertG.displaying('Désolé, non disponible !')">Essayez de récupérer votre compte</span> </span>
        </div>
        <div @click='loggin' class="insButtonI01" ><ButtonI0 message = "Continuez" /></div>
        <div class = "insOR1">
            <span class ="insPolicy1">OR</span>
        </div>
        <div class="insLast1">
            <a @click="toInscription">Vous n'avez pas de compte ? Inscrivez vous</a>
        </div>
    </form>
    <AlertG ref="alertG"/>
    <LLoading1 :visible="loading"/>
</section>
</template>
<style src="./inscription.css">
</style>
<script src = "./connexion.js">
</script>
