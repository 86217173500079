<template>
<section v-show="Display" @click="closing" ref='main' class="inscription1">
        <form class = "insMainSection1" action="#">
            <div class = "insTitle1">
                <span>Créez un compte pour pouvoir acheter des tickets </span>
                <div v-show="DispErr" class="insError1">{{error}}</div>
            </div>
            <div class ="insInput1">
                <InputI0 placeholder="Entrez votre nom" ref="surname" type = "text"/>
            </div>
            <div class ="insInput1">
                <InputI0 placeholder="Entrez num téléphone" ref="contact" type = "number" />
            </div>
            <div class ="insInput1">
                <InputI0 placeholder="Entrez un mot de passe" ref="password" type = "password" pattern="^.{4,22}$" />
            </div>
            <div class ="insInput1">
                <InputI0 placeholder="Confirmez le mot de passe" ref="confirmpw" type = "password" pattern="^.{4,22}$" />
            </div>
            <div class="insLaw1">
                <span class ="insPolicy1">By signing up, you confirm that you've read and accepted our <span @click="$refs.conditions.show()" >Terms of Service</span> and <span @click="$refs.conditions.show()">Privacy Policy</span>. </span>
            </div>
            <div @click='save' class="insButtonI01" ><ButtonI0 message = "Continuez" /></div>
            <div class = "insOR1">
                <span class ="insPolicy1">OR</span>
            </div>
            <div class="insLast1">
                <a @click="toConnexion">Vous avez déjà un compte ? Connectez vous</a>
            </div>
        </form>
    <LLoading1 :visible="loading"/>
    <AlertG ref="alertG" @okay="Display=false"/>
    <Conditions ref="conditions"/>
</section>
</template>
<style src="./inscription.css">
</style>
<script src = "./inscription.js">
</script>
