<template>
    <div v-if="Display" class="buy9">
        <div class="buy9Box">
            <div class="buy9title">
                Pour éffectuer l'achat du ticket de l'agence <b>{{ $store.state.currentAgency.nom }}</b>
                Veuillez entrer vorte numéro <b>OM</b> ou <b>MOMO</b>.
            </div>
            <div><input v-model="tel" type="number" placeholder="ex: 698456723" /></div>
            <div class="buy9button"><AndroidButton radius="0" @clicked="warning" >ACHETER</AndroidButton></div>
            <AndroidButton @clicked="Display=false" radius="2em" class="buy9Cross"><img src="./img/cross.svg"/></AndroidButton>
        </div>
        <LLoading :visible="loading"/>
        <AlertG ref="alertG" @okay="buying"/>
        <AlertG ref="alertF" />
        <AlertG class="buy9Alert" ref="alertH" @okay="Display=false"/>
    </div>
</template>
<style>
.buy9 {
  position: fixed;
  left: 0%;
  top:0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.63);
  display: flex;justify-content: center;align-items: center;
  text-align: center;
  z-index: 30;
}
.buy9Box {
  position:relative;
  width: 600px;
  max-height: 90%;
  max-width: 90%;
  background-color: white;
  border-radius: 0.4em;
}
.buy9title {
  text-align: left;
  padding:20px;
  padding-top: 30px;
  line-height: 23px;
  font-size: 15px;
}
.buy9title + div > input {
  padding: 10px;
  height: 40px;
  border: solid 2px;
  border-radius: 0.4em;
  font-size: 15px;
}
.buy9button {
  margin-top: 25px;
  display: flex;justify-content: center;align-items: center;
  margin-bottom: 20px;
}
.buy9button > div {
  box-sizing:border-box;
  padding: 7px;
  width: 140px;
  background-color:rgb(0, 81, 255);
  border: none;
  color: white;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
}
.buy9Cross {
  position: absolute;
  right: 0px;
  top:0px;
  margin-right: 5px;
  margin-top: 5px;
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.2);
}
.buy9Cross img {
  width: 10px;
  min-width: 10px;
}
.buy9Alert .alertGBox {
  max-height:70%;
  margin-bottom: 100px;
}

</style>
<script>
import AndroidButton from '@/components/AndroidButton'
import AlertG from '@/components/alertG/AlertG'
import LLoading from '@/components/lLoading1/LLoading1'

export default {
  name: 'buy',
  components: {
      AndroidButton,
      AlertG,
      LLoading
  },
  data () {
    return {
        loading:false,
        Display: false,
        tel:'',
        toSend:{},
        vendu:0
    }
  },
  emits: ['ended','getOut'],
  methods: {
    close () {
      this.loading = false
      this.Display = false
      this.tel = ''
    },
    show (pl) {
        this.toSend = pl.toSend
        this.vendu = pl.vendu
        this.Display = true
    },
    warning () {
        if(this.tel.length <7) this.$refs.alertF.displaying('Numéro incorrect !')
        else this.$refs.alertG.displaying('Vous allez recevoir un message de confirmation, veuillez entrer directement le code sans tarder !')
    },
    buying () {
      let t = this.tel+''
      if(t.length != 9 || t[0] !=6) {
        this.$refs.alertF.displaying('Numéro incorrect')
        return
      }

      this.loading = true
      let toSend = {...this.toSend,buyer:this.tel,nomAgence: this.$store.state.currentAgency.nom}
      this.axios.post(this.$store.state.baseUrl + 'user.php', {...toSend})
      .then(({data}) => {
       
        this.loading = false
        
        //this.reloadListOnClose = true
        if(data == 'error7x86re543') {
          this.$emit('getOut')
        }
        else if(data.status === 'unsufficent' ) {
          //this.infos.ticketVendu = data.newVendu
          //vendu = data.newVendu
          this.$refs.alertH.displaying('Echec ! Nombre de ticket restant pour ce voyage insuffisant !')
        }
        else if(data.status === 'echec') {// the transaction was not launch 
          this.$refs.alertH.displaying(data.error)
        }
        else if(data.status === 'undeterminedError') {
          this.$refs.alertH.displaying('Erreur indéterminée')
        }
        else if(data.status === 'failed') {
          this.$refs.alertH.displaying(`Erreur la transaction a <b>échoué</b> pour l'une des raisons suivantes:<br> 
           - Vous avez annulé la transaction.<br>
           - La transaction n'a pas été validé dans les temps et ne peut plus l'etre, veuillez réessayer.<br>
           - Vous n'avez pas suffisament d'argent sur votre compte.<br> 
           - Limites de payement atteintes.<br>
           - Compte non autorsisé.<br>
           `)
        }
        else if(data.status === 'success') {
          this.$refs.alertH.displaying("Félicitations ! Ticket acheté avec succès. Allez dans le menu \"Mes Tickets\" pour acceder au code.<br>")
        }
        this.$emit('ended',data.newVendu)
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
      })
    }
  }
}

</script>
