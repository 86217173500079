<template>
    <div class="compte">
        <div v-show="!$store.state.user.connected" class="compteBody">
            <div class="comptePub">
                <div><img src="./img/checked.svg"/>Planifiez vos voyages.</div>
                <div><img src="./img/checked.svg"/>Economisez du temps</div>
                <div><img src="./img/checked.svg"/>En toute sécurité</div>
            </div>
            <AndroidButton @clicked="$refs.inscription.Display=true" class="compteButton" radius="0.1em">Créer un compte</AndroidButton>
            <AndroidButton @clicked="$refs.connexion.Display=true" radius="0.1em">Se connecter</AndroidButton>
        </div>
        <div v-show="$store.state.user.connected" class="compteBody">
            <div class="comptePub">
                <div><img src="./img/account1.svg"/>{{ $store.state.user.name }}</div>
                <div><img src="./img/phone.svg"/>{{ $store.state.user.tel }}</div>
            </div>
            <AndroidButton @clicked="deconnexion"
            class="compteButton" radius="0.1em">Se déconnecter</AndroidButton>
        </div>
        <Inscription ref="inscription" @connexion="$refs.connexion.Display=true"/>
        <Connexion ref = "connexion" @inscription="$refs.inscription.Display=true"/>
        <Confirm ref="confirm" @clicked="verdict"/>
    </div>
</template>
<style src="./compte.css">
</style>
<script src = "./compte.js">
</script>
