<template>
    <footer v-show="Display" class="lFooter">
        <div><Home :selected='elts.home' @clicked="change('home')" /><span>Home</span></div>
        <div><Bus :selected='elts.reserve' @clicked="change('reserve')"/><span>Réserver</span></div>
        <div><Ticket :selected='elts.tickets' @clicked="change('tickets')" /><span>Mes tick...</span></div>
        <div><User :selected='elts.compte' @clicked="change('compte')"/><span>Compte</span></div>
        <div><Service :selected='elts.contact' @clicked="change('contact')"/><span>Contac...</span></div>
        <aside>
            <span> Vous êtes sur <b style="margin-left:5px"> {{ $store.state.currentAgency.nom }}</b></span>
            <AndroidButton @clicked="change('/')" radius="3em"><img src="./img/reload.svg"/></AndroidButton>
        </aside>
    </footer>
</template>
<style src="./lFooter.css">
</style>
<script src = "./lFooter.js">
</script>
