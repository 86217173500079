<template>
    <div class="start">
        <div class="startFirstC">
            <div class="startFirst">
                <div class="startFHead">
                    <div>dzoula</div>
                </div>
                <div class="startFHead1">
                    <h2>Reserver facilement des tickets de bus !</h2>
                    <h4>Bienvenue sur Dzoula !</h4>
                </div>
                <div>
                    <HInput @search="search" />
                </div>
                <div class="startList">
                    <AndroidButton @clicked="toAgency(ag)" v-for="(ag,index) in agenciesList" :key="index" radius="0.6em">
                        <img src="./img/bus1.svg"/>
                        <span>{{ ag.nom }}</span>
                    </AndroidButton>
                </div>    
            </div>
        <LLoading1 :visible='loading' />
        <Confirm ref="confirm" @clicked="verdict"/>
        </div>
    </div>
</template>
<style src="./start.css">
</style>
<script src = "./start.js">
</script>
