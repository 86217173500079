<template>
<div class="radioButton">
    <div>
        <div @click="select(index)" v-for="(elt,index) in elts" 
            :key="index"
            :style="{width:100/elts.length + '%'}"
            >
            <div :style="{backgroundColor: elt.selected == true? 'rgb(0, 81, 255)':'white'}">
                </div>{{elt.name}}
        </div>    
    </div>
</div>
</template>
<style>
    .radioButton {
        padding-left:15px ;
        display: flex;justify-content: center;align-items: center;
    }
    .radioButton > div{
        box-sizing: border-box;
        height: 20px;
        width: 320px;
        font-size: 13px;
        display: flex;justify-content: flex-start;align-items: center;
    }
    .radioButton > div > div {
        display: flex; justify-content: flex-start;align-items: center;
        cursor:pointer
    }
    .radioButton > div > div > div {
        display: block;
        width: 10px; min-width: 10px;
        height: 10px; min-height: 10px;
        border-radius: 3em;
        border: solid 3px rgb(40, 121, 255);
        margin-right:10px;
    }

    /*----- responsive managment zone... ------------------ */
    @media screen and (max-width: 440px) {
        .radioButton {
            width: 90%;
        }
    }
</style>
<script>
export default {
  name: 'radioButton',
  data () {
    return {
      elts:[{name:'Entreprise',selected:false},{name:'Personne',selected:false}],
      value:''
    }
  },
  methods: {
      select (index) {
          let i, max = this.elts.length
          for(i=0;i<max;i++){
              this.elts[i].selected = false
          }
          this.elts[index].selected = true
          this.value = this.elts[index].name
      }
  }
}
</script>
