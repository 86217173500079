<template>
    <div @click="closing" ref="main" class ="reload" v-show="Display">
      <div>
        <span> Impossible de se connecter </span>
        <AndroidButton @clicked="reloading" radius="0.4em" class="reloadButton">
          Réessayer<img src='./img/reload.svg' />
        </AndroidButton>
      </div>
    </div>
</template>
<style src="./reload.css">
</style>
<script src="./reload.js">
</script>
