import AndroidButton from '@/components/AndroidButton'
import LLoading1 from '@/components/lLoading1/LLoading1'
import Reload from '@/components/reload/Reload'

export default {
  name: 'ticketList',
  components: {
    AndroidButton,
    LLoading1,
    Reload
  },
  data () {
    return {
      loading:false,
      top:'100%',
      opacity:0,
      weekDay:["DIMANCHE","LUNDI","MARDI","MERCREDI","JEUDI","VENDREDI","SAMEDI"],
      trajet:{},
      days: [],
      daysList:1,
      current:{},
      voyages:[]
    }
  },
  emits:["buy"],
  methods: {
    close () {
      this.top = "100%"
      this.opacity = 0
      this.loading = false
    },
    show (trajet) {
      this.top = "0%"
      this.opacity = 1
      this.trajet = trajet
      this.changeList(1)
    },
    buy (voyage) {
      this.$emit('buy',voyage)
    },
    selectDay (day) {
      this.current.chosen = false
      this.current = day
      this.current.chosen = true
      this.getInfos()
    },
    changeList (l) {
      this.daysList = l
      this.current.chosen = false
      this.current = l == 1 ? this.days[0] : this.days[4]
      this.current.chosen = true
      this.getInfos()
    },
    getInfos () {
      this.loading = true
      this.axios.post(this.$store.state.baseUrl + 'user.php', {
        nomAgence: this.$store.state.currentAgency.nom,
        type:'getVoyage',
        trajet: this.trajet.id,
        annee:this.current.annee,
        mois: this.current.mois,
        jour: this.current.jour
      })
      .then(({data}) => {
        this.loading = false
        this.voyages = data
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
        this.$refs.reload.show()
      })
    }
  },
  mounted () {
    let i= 0
    for(i=0;i<8;i++) {
      let d = new Date()
      d.setDate(d.getDate() + i)
      let m = d.getMonth()
      let y = d.getFullYear()

      this.days.push({annee:y,mois:m+1,jour:d.getDate(),
        day:this.weekDay[d.getDay()],chosen:false })
    }
    this.current = this.days[0]
    this.current.chosen = true
  }
}
