<template>
    <AndroidButton @clicked="$emit('clicked')">
        <svg
            class="footerHome"
            width="599.95001"
            height="600"
            viewBox="0 0 767.93602 768"
            version="1.1"
            id="svg4"
            sodipodi:docname="home-filled-svgrepo-com.svg"
            inkscape:version="1.1 (c4e8f9e, 2021-05-24)"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg">
            <defs
            id="defs8" />
            <path
            :fill="selected ? 'rgba(63, 69, 146)':'black'"
            d="M 384,0 0,319.936 V 768 H 255.936 V 512 H 512 V 768 H 767.936 V 319.936 Z"
            id="path2" />
        </svg>
    
    </AndroidButton>
</template>
<style>
.footerHome {
    width: 25px;
    height: 27px;
}
</style>
<script>
import AndroidButton from '@/components/AndroidButton'
  
  export default {
  name: 'Home',
  props: {
      selected: {
          type: Boolean,
          default:false
      }
  },
  emits:["clicked"],
  components: {
    AndroidButton      
  }
}
</script>
