import AndroidButton from '@/components/AndroidButton'
import TicketCode from '@/components/ticketCode/TicketCode'
import LLoading1 from '@/components/lLoading1/LLoading1'
import Reload from '@/components/reload/Reload'


export default {
  name: 'tickets',
  components:  {
    AndroidButton,
    TicketCode,
    LLoading1,
    Reload
  },
  data () {
    return {
      loading:false,
      menu: [{chosen:true},{chosen:false}],
      tickets:[]
    }
  },
  computed: {
    ticketsList () {
      return this.menu[0].chosen ? 
        this.tickets.filter((tk)=>{ return tk.validated ==0 && tk.difference > -1 }) : 
        this.tickets.filter((tk)=>{ return tk.validated !=0 || tk.difference <= -1 }) 
    }
  },
  methods: {
    changeMenu(index) {
      this.menu[0].chosen = false
      this.menu[1].chosen = false
      this.menu[index].chosen = true
    },
    showTicket (ticket) {
      this.$refs.ticketCode.show(ticket)
    },
    getInfos () {
      if(!this.$store.state.user.connected) return

      this.loading = true
      this.axios.post(this.$store.state.baseUrl + 'user.php', {
        type:'getTicket',
        userId: this.$store.state.user.id,
        nomAgence: this.$store.state.currentAgency.nom
      })
      .then(({data}) => {
        
        if(data == 'error7x86re543') this.$router.push('/')
        if(data.constructor === Array && data.length > 0) localStorage.setItem(this.$store.state.currentAgency.nom,JSON.stringify(data))
        if(data.constructor === Array ) this.tickets = data
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
        this.$refs.reload.show()
      })
    }
  },
  mounted () {
    let t = localStorage.getItem(this.$store.state.currentAgency.nom)
    //console.log(t)
    //alert(t)
    if(t != null && this.$store.state.user.connected) this.tickets = JSON.parse(t)
    else this.ticket = []

    this.getInfos()
  },
  beforeRouteEnter(to, from) {
    if(typeof from.name == 'undefined') return {name:'start'}
  },
  beforeRouteLeave (/*to, from*/) {
    if(this.$refs.ticketCode.opacity == 1) {//---- ticketCode open
      this.$refs.ticketCode.close()
      return false
    }
  }
}
