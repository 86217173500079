<template>
    <div class="reserve">
        <Buy @getOut="getOut" @ended="(vendu)=>$refs.buyTicket.ending(vendu)" ref="buy"/>
        <Inscription style="z-index:10" ref="inscription" @connexion="$refs.connexion.Display=true"/>
        <Connexion style="z-index:10" ref = "connexion" @inscription="$refs.inscription.Display=true"/>
        <TicketList @buy="(voyage)=>$refs.buyTicket.show(voyage)" style="z-index:3" ref="ticketList"/>
        <BuyTicket @buy="(inf)=> $refs.buy.show(inf)" style="z-index:4" @reloadList="$refs.ticketList.getInfos()" 
        @inscription="$refs.inscription.Display=true" ref="buyTicket"/>

        <div class="reserveHead"> Choississez un trajet</div>    
        <div class="reserveButtons">
            <AndroidButton v-for="(tj, index) in $store.state.trajets.elts" :key="index"
            radius="0em" @clicked="showList(tj)">
                <span>{{ tj.depart + ' vers ' + tj.arrivee }}</span>
            </AndroidButton>
        </div>
    <LLoading1 :visible='loading' />
    <Inscription ref="inscription" @connexion="$refs.connexion.Display=true"/>
    <Connexion ref = "connexion" @inscription="$refs.inscription.Display=true"/>
    <Reload @reload="getTrajets" ref="reload" />
    </div>
</template>
<style src="./reserve.css">
</style>
<script src = "./reserve.js">
</script>
