<template>
    <AndroidButton @clicked="$emit('clicked')">
      <svg
      class="footerTicket"
         :fill="selected ? 'rgba(63, 69, 146)':'black'"
         width="749.95312"
         height="750"
         viewBox="0 0 479.97 480"
         version="1.1"
         id="svg4"
         sodipodi:docname="ticket-sharp-svgrepo-com.svg"
         inkscape:version="1.1 (c4e8f9e, 2021-05-24)"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:svg="http://www.w3.org/2000/svg">
         <defs
         id="defs8" />
         <path
         d="m 410.24,111.72 -10.94,10.94 a 29.698485,29.698485 0 0 1 -42,-42 L 368.24,69.72 298.52,0 l -88,88 -4,12.09 -12.09,4 L 0,298.52 69.76,368.28 80.7,357.34 a 29.698485,29.698485 0 0 1 42,42 l -10.94,10.94 69.72,69.72 194.4,-194.4 4,-12.09 12.09,-4 88,-88 z m -208.56,5.43 21.87,-21.87 33,33 -21.88,21.87 z m 43,43 21.88,-21.88 32.52,32.52 -21.88,21.88 z m 42.56,42.56 21.88,-21.88 32.52,32.52 -21.84,21.93 z m 75.57,75.56 -33,-33 21.87,-21.88 33,33 z"
         id="path2" />
      </svg>

    </AndroidButton>
</template>
<style>
.footerTicket {
    width: 27px;
    height: 27px;
}
</style>
<script>
import AndroidButton from '@/components/AndroidButton'
  
  export default {
  name: 'Ticket',
  props: {
      selected: {
          type: Boolean,
          default:false
      }
  },
  emits:["clicked"],
  components: {
    AndroidButton      
  }
}
</script>
