import AndroidButton from '@/components/AndroidButton'
import Inscription from '@/components/inscription/Inscription'
import Connexion from '@/components/inscription/Connexion'
import Confirm from '@/components/confirm/Confirm'


export default {
  name: 'compte',
  components : {
    AndroidButton,
    Inscription,
    Connexion,
    Confirm
  },
  data () {
    return {
    }
  },
  methods: {
    deconnecting () {
      this.setCookie('userId','',0)
      this.setCookie('userName','',0)
      this.setCookie('userTel','',0)
      this.$store.commit('deconnexion')
    },
    deconnexion () {
      this.$refs.confirm.show('Vous-vous vraiment vous déconnecter ? veuillez garder vos informations de compte pour pouvoir vous reconnecter ultérieurement.')
    },
    verdict(vdt) {
      if(vdt == 'yes') this.deconnecting()
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  },
  beforeRouteEnter(to, from) {
    if(typeof from.name == 'undefined') return {name:'start'}
  },
  beforeRouteLeave () {
    if(this.$refs.inscription.Display == true) {
      this.$refs.inscription.Display = false
      return false
    }
    if(this.$refs.connexion.Display == true) {
      this.$refs.connexion.Display = false
      return false
    }
  }
}
