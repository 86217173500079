<template>
<div>
    <input
      v-bind:placeholder = "placeholder"
      v-bind:type ="type"
      v-bind:max ="max"
      v-bind:min ="min"
      v-bind:pattern ="pattern"
      v-model="message" class ="inputI0"
      />
</div>
</template>
<style>
    .inputI0 {
        box-sizing: border-box;
        background-color: transparent;
        max-width: 90%;
        height: 45px;
        padding-left: 10px;
        padding-right: 10px;
        width: 320px;
        font-size: 13px;
        border: solid 1px silver;
        border-radius: 0.6em!important;
        color: rgb(60,60,60);
    }
    .inputI0:focus {
        border-color: rgb(83, 130, 231);
        border-width: 2px;
    }

    /*----- responsive managment zone... ------------------ */
    @media screen and (max-width: 440px) {
        .inputI0 {
            width: 90%;
        }
    }
</style>
<script>
export default {
  name: 'InputI0',
  props: {
    placeholder: String,
    type: String,
    max: Number,
    min: Number,
    pattern: String
  },
  data () {
    return {
      message:''
    }
  }
}
</script>
