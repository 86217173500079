import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/home/Home'
import Contact from '@/components/ContactPage/Contact'
import Reserve from '@/views/reserve/Reserve'
import Tickets from '@/views/tickets/Tickets'
import Compte from '@/views/compte/Compte'
import Start from '@/views/start/Start'
const routes = [
  {
    path: '/',
    name: 'start',
    component: Start
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: Reserve
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: Tickets
  },
  {
    path: '/compte',
    name: 'compte',
    component: Compte
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
