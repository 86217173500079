<template>
    <div class="HInput">
      <input v-model="word" ref="input" @input="$emit('search',word)" @search="search" type="search" placeholder="Rechercher agence"/>
      <img src="./img/travel.svg"/>
    </div>
</template>
<style>
@keyframes blink {
  from {
    background-color: rgba(128, 128, 128, 0.7);
  }
  to {
    background-color: transparent;
  }
}
.HInput {
    width: 100%;
    height: 55px;
    min-height: 55px;
    /*color:white;*/
    background-color: rgba(0,0,0,0.9);
    /*box-shadow: 0px -1px 3px 3px rgba(0, 0, 0, 0.3);*/
    padding-left: 20px;
    padding-right: 20px;
    border-radius:0.1em;
    display: flex;justify-content: center;align-items: center;
    border: solid 1px #37404A;
    border:solid black 2px;
    border-radius: 0.4em;
    box-sizing: border-box;
}
.HInput > input{
  width: 100%;
  max-width: 90%;
  font-size: 15px;
  border: none;
  background-color: transparent;
  color:white;
}
.HInput > img {
  margin-left: 15px;
  width: 20px;
  min-width: 25px;
  border:solid 2px transparent;
}
</style>
<script>
  export default {
  name: 'HInput',
  data () {
      return {
        word:''
      }
  },
  emits:["search"],
  methods: {
      search () {
        this.$refs.input.blur()
        this.$emit('search',this.word)
      }
  }
}
</script>
