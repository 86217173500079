<template>
    <div :style="{top:top,opacity:opacity}" class="ticketList">
      <div :style="{top:top,opacity:opacity}" class="ticketListH">
        <div class="ticketListHH">
           <div class="ticketListPath">Tickets pour {{ trajet.depart }} <img src="./img/bus.svg"> {{ trajet.arrivee }}</div>
          <AndroidButton @clicked="close" class="ticketListAB1"><img src="./img/cross.svg"></AndroidButton>
        </div>
        <div class="ticketListDay">

          <div v-show="daysList == 1" class="ticketListDayElt" v-for="(dy,index) in days.slice(0,4)" :key="index"
            :style="{color:dy.chosen ? 'rgb(60, 94, 189)':'silver',
              marginLeft: index == 0 ? '0px' : '2px'
            }"
          >
            <AndroidButton @clicked="selectDay(dy)" radius="0em" class="ticketListDayEltB">
              <span>{{10 > dy.jour ? '0'+dy.jour : dy.jour}}</span>
              <div>
                <span>{{ dy.day.slice(0,3) }}</span>
                <span>{{ dy.day.slice(3,6) }}</span>
              </div>
            </AndroidButton>
          </div>

          <div v-show="daysList == 2" class="ticketListDayElt" v-for="(dy,index) in days.slice(4,8)" :key="index"
            :style="{color:dy.chosen ? 'rgb(78, 78, 180)':'silver',
              marginLeft: index == 0 ? '0px' : '2px'
            }"
          >
            <AndroidButton @clicked="selectDay(dy)" radius="0em" class="ticketListDayEltB">
              <span>{{10 > dy.jour ? '0'+dy.jour : dy.jour}}</span>
              <div>
                <span>{{ dy.day.slice(0,3) }}</span>
                <span>{{ dy.day.slice(3,6) }}</span>
              </div>
            </AndroidButton>
          </div>
          <img @click="changeList(2)" v-show="daysList == 1" class="ticketListDNext" src="./img/arrow1.svg"/>
          <img @click="changeList(1)" v-show="daysList == 2" class="ticketListDNext" src="./img/arrow.svg"/>
        </div>
      </div>


      <div class="ticketListBody">
        <div v-show="voyages.length == 0 "> Aucun voyage trouvé pour ce jour </div>
        <div v-for="(vg, index) in voyages" :key="index" class="ticketListBElt">
          <div :style="{backgroundColor:$store.state.classColor[vg.classe]}" class="ticketListBadge">
            {{ vg.classe }}
          </div>
          <div>
            <div> Départ à {{ vg.heureDepart }} </div>
            <span> {{ vg.prixTicket }} fcfa </span>
          </div>
          <AndroidButton v-show="vg.ticketVendu >= vg.nbrTicket" radius="0.6em" class="ticketListBuy" style="backgroundColor:rgb(209, 89, 89)" ><span>Fermé</span></AndroidButton>
          <AndroidButton v-show="vg.ticketVendu < vg.nbrTicket" @clicked="buy(vg)" radius="0.6em" class="ticketListBuy"><span>Acheter</span></AndroidButton>
        </div>
      </div>    
    
    <LLoading1 style="z-index:5" :visible='loading' />
    <Reload @reload="getInfos" ref="reload" />
    </div>
</template>
<style src="./ticketList.css">
</style>
<script src="./ticketList.js">
</script>
