<template>
    <div :style="{top:top,opacity:opacity}" class="ticketCode">
        <div class="ticketCodeH">
            <span>Mon ticket </span>
            <AndroidButton @clicked="close" radius="2em" class="ticketCodeHCross">
                <img src="./img/cross.svg"/>
            </AndroidButton>
        </div>
        <VueQrcode
        :width="260"
        :value="typeof infos.code == 'undefined' ? '0000':infos.code"
        type="image/png"
        :color="{ dark: '#000000ff', light:'#ffffffff' }"
        @change="onDataUrlChange"
        />
        <div class="ticketCodeInfos">
            <div class="ticketCodeIUser"><img src="./img/acheteur.svg"><span>Acheteur</span><div>{{ $store.state.user.name }}</div></div>
            <!-- <div><img src="./img/key.svg"><span>Code</span><div>{{ infos.code }}</div></div> -->
            <div><img src="./img/trajet.svg"><span>Trajet</span><div>{{ infos.depart }} à {{ infos.arrivee }}</div></div>
            <div><img src="./img/classe.svg"><span>Classe</span><div>{{ infos.classe }}</div></div>
            <div><img src="./img/seat.svg"><span>Position</span><div>{{ infos.position+1 }}</div></div>
            <div><img src="./img/prix.svg"><span>Prix</span><div>{{ infos.prixTicket }} fcfa</div></div>
            <div><img src="./img/total.svg"><span>Total payé</span><div>{{ infos.prixTicket*infos.nombre }} fcfa</div></div>
            <div><img src="./img/nombre.svg"><span>Nombre de place</span><div>{{ infos.nombre }}</div></div>
            <div><img src="./img/depart.svg"><span>Départ le</span><div>{{infos.jour+' '+this.$store.state.mois[infos.mois-1]+' '+infos.annee}}</div></div>
            <div><img src="./img/heure.svg"><span>Heure de départ</span><div>{{ infos.heureDepart }}</div></div>
            <div><img src="./img/location.svg"><span>Lieu de départ</span><div>{{ infos.lieu }}</div></div>
            <!-- <div><img src="./img/expire.svg"><span>Expiré</span><div>{{ infos.validated ? 'oui':'non' }}</div></div> -->
    
            <!-- <p class="ticketCodeHelp"> Faite scanner le qr code au guichet ou copier le code et présentez le.</p> -->
            <p class="ticketCodeHelp"> Faite scanner le qr code au guichet de l'agence.</p>
        </div>
    </div>
</template>
<style src="./ticketCode.css">
</style>
<script src = "./ticketCode.js">
</script>
