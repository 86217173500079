import VueQrcode from 'vue-qrcode'
import AndroidButton from '@/components/AndroidButton'

export default {
  name: 'ticketCode',
  components: {
    VueQrcode,
    AndroidButton
  },
  data () {
    return {
      top:'100%',
      opacity:0,
      dataUrl:null,
      qrImage:'',
      infos:{}
    }
  },
  methods: {
    onDataUrlChange(dataUrl) {
      this.dataUrl = dataUrl
    },
    close () {
      this.top = "100%"
      this.opacity = 0
    },
    show (infos) {
      this.top = "0%"
      this.opacity = 1
      this.infos = infos
    }
  },
}
