import AndroidButton from '@/components/AndroidButton'
import TicketList from '@/components/ticketList/TicketList'
import BuyTicket from '@/components/buyTicket/BuyTicket'
import Buy from '@/components/buyTicket/Buy'
import LLoading1 from '@/components/lLoading1/LLoading1'
import Inscription from '@/components/inscription/Inscription'
import Connexion from '@/components/inscription/Connexion'
import Reload from '@/components/reload/Reload'


export default {
  name: 'Reserve',
  components: {
    AndroidButton,
    TicketList,
    BuyTicket,
    Buy,
    LLoading1,
    Inscription,
    Connexion,
    Reload
  },
  data () {
    return {
      loading:false
    }
  },
  methods: {
    getOut() {
      this.$refs.buyTicket.opacity = 0
      this.$refs.ticketList.opacity = 0
      this.$router.push('/')
    },
    showList (trajet) {
      this.$refs.ticketList.show(trajet)
    },
    getTrajets () {
      this.loading = true
      this.axios.post(this.$store.state.baseUrl + 'user.php', {
        type:'getTrajet',
        nomAgence: this.$store.state.currentAgency.nom
      })
      .then(({data}) => {
        if(data == 'error7x86re543') this.$router.push('/')
        if(data.constructor === Array ) this.$store.commit('setTrajet',data)
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
        this.$refs.reload.show()
      })
    }
  },
  mounted () {
    if(!this.$store.state.trajets.loaded) this.getTrajets()
  },
  beforeRouteEnter(to, from) {
    if(typeof from.name == 'undefined') return {name:'start'}
  },
  beforeRouteLeave (/*to, from*/) {
    if(this.$refs.buy.Display ) {
      this.$refs.buy.close()
      return false
    }
    if(this.$refs.inscription.Display || this.$refs.connexion.Display ) {//---- buyTicket
      this.$refs.inscription.Display = false
      this.$refs.connexion.Display = false
      return false
    }
    if(this.$refs.buyTicket.opacity == 1) {//---- buyTicket
      this.$refs.buyTicket.close()
      return false
    }
    if(this.$refs.ticketList.opacity == 1) {//---- ticketList open
      this.$refs.ticketList.close()
      return false
    }
  }
}
