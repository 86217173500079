import InputI0 from '@/components/inscription/InputI0.vue'
import ButtonI0 from '@/components/inscription/ButtonI0.vue'
import ButtonI1 from '@/components/inscription/ButtonI1.vue'
import LLoading1 from '@/components/lLoading1/LLoading1'
import AlertG from '@/components/alertG/AlertG'

export default {
  name: 'Connexion',
  components: {
    InputI0,
    ButtonI0,
    ButtonI1,
    LLoading1,
    AlertG
  },
  data () {
    return {
      loading:false,
      Display:false,
      DispErr: false,
      error:''
    }
  },
  emits:["inscription"],
  methods: {
    closing (event) {
      if( event.target == this.$refs.main ) this.Display = false
    },
    toInscription () {
      this.$emit('inscription')
      this.Display = false
    },
    loggin () {
      if (!this.validation()) return
      this.DispErr = false
      this.loading = true
      this.axios.post(this.$store.state.baseUrl + 'account.php', {
        type:'connexion',
        contact: this.$refs.contact.message,
        password: this.$refs.password.message
      })
        .then(({data}) => {
          this.loading = false
          if(data === 'notExist') {
            this.error = 'Compte inexistant'
            this.DispErr =true
          }
          else {
            this.setCookie('userId',data.id,3500)
            this.setCookie('userName',data.name,3500)
            this.setCookie('userTel',data.tel,3500)
            let user = {id:data.id,name:data.name,tel:data.tel,connected:true}
            this.$store.commit('connexion',user)
            this.Display = false
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    validation () {
      this.DispErr = false
      // let regex2 = /^[0-9]{6,11}.$/
      let t = this.$refs.contact.message+''
      if(t.length != 9 || t[0] !=6) {
        this.error = 'numéro télépnone incorrect'
        this.DispErr = true
        return false
      }
      if (this.$refs.password.message == '') {
        this.error = 'mot de passe incorrect'
        this.DispErr = true
        return false
      }
      return true
    },
    //-- cookie useful functions ------------
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  }
}
