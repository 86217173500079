import HInput from './HInput'
import AndroidButton from '@/components/AndroidButton'
import LLoading1 from '@/components/lLoading1/LLoading1'
import Confirm from '@/components/confirm/Confirm'

export default {
  name: 'start',
  
  components: {
    HInput,
    AndroidButton,
    LLoading1,
    Confirm
  },
  data () {
    return {
      loading:false,
      agenciesList:[],
      canMove:false
    }
  },
  methods: {
    exiting () {
      this.canMove = true
    //eslint-disable-next-line no-undef
    //console.log(Capacitor.getPlatform())
    // eslint-disable-next-line no-undef
    if(Capacitor.getPlatform() == 'web') window.history.go(1 - window.history.length)
    else this.ExitCapacitorApp()
    //console.log(window.history.state.position)
    },
    verdict(vdt) {
      if(vdt == 'yes') this.exiting()
    },
    toAgency (agency) {
      this.$store.commit('setCurrentAgency',agency)
      this.canMove = true
      this.$router.push('/home')
    },
    getAgency () {
      this.loading = true
      this.axios.post(this.$store.state.baseUrl + 'account.php', {
        type:'getUserAgency'
      })
      .then(({data}) => {
        if(data.length > 0)localStorage.setItem('__agencies__', JSON.stringify(data))
        this.$store.commit('setAgencies',data)
        this.agenciesList = data
        this.loading = false
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
        //------ lets load agencies from local storage--------
        let t = localStorage.getItem("__agencies__")
        if(t != null) {
          this.agenciesList = JSON.parse(t)
          this.$store.commit('setAgencies',JSON.parse(t))
        }
    
      })
    },
    removeAccents (str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
    },
    search (word) {
      this.agenciesList = []
      let i=0 , count = 0, elts = this.$store.state.agencies.elts
      let toSearch = this.removeAccents(word)

      for(i=0; i<elts.length; i++){
          //console.log(datas[i].country_name)
          let str = this.removeAccents(elts[i].nom)
          if(str.includes(toSearch)) {
              this.agenciesList.push(elts[i])
              count++
          }
          if (count > 40) break
      }
    }
  },
  mounted () {
    if(!this.$store.state.agencies.loaded) this.getAgency()
    else this.agenciesList = this.$store.state.agencies.elts
  },
  beforeRouteLeave () {
    if(!this.canMove) {
      this.$refs.confirm.show("Voulez vous vraiment <b>quitter DZOULA ?</b>")
      return false
    }
  }
}
