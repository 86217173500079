<template>
    <div class="landing">
        <div class="landFirst" :style="{backgroundImage:'url('+wallpaper+')'}" >
            <div class="landFirstCover"></div> 
            <div class="landFirstCovText">
                <div class="landFHead">
                    <div class="landLogo" :style="{backgroundImage:'url('+logo+')'}">
                    </div>
                    <span>{{ $store.state.currentAgency.nom }}</span>
                    <AndroidButton @clicked="$router.push('/')" style="width:30px;height:30px;background-color:white;" radius="3em"><img src="./img/reload.svg"/></AndroidButton>
                </div>
                <div class="landFHead1">
                    <h4>Salut !</h4>
                    <h2>Où est ce que vous souhaitez aller ?</h2>
                </div>
                <div>
                    <HButton @clicked="$router.push('/reserve')"/>
                </div>
            </div>
        </div>
        <div class="landSecond">
            <img/>
            <div class="landSPresentC">
                <div class="landSPresent">
                    <h2>{{ $store.state.currentAgency.nom }} sur Dzoula !</h2>
                    <span>Achetez rapidement un ticket de bus et évitez les attentes interminables.
                        Tout ce que vous avez à faire c'est valider l'achat du ticket et une fois 
                        à l'agence présentez le code que vous recevrez.
                    </span>
                    <AndroidButton @clicked="$router.push('/reserve')" style="padding:0px;" radius="0.1em">
                        <span> Commencez ! </span>
                    </AndroidButton>
                </div>
            </div>
        </div>
    </div>
</template>
<style src="./home.css">
</style>
<script src = "./home.js">
</script>
