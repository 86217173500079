<template>
    <div class="tickets">
        <TicketCode style="z-index:2" ref="ticketCode"/>
        <div class="ticketsHead">
            <div class="ticketsH1">
                <span>Mes tickets</span>
            </div>
            <div>
                <AndroidButton :style="{color: menu[0].chosen ? 'rgb(60, 94, 189)' : 'gray'}"
                @clicked="changeMenu(0)"
                class="ticketsButton" radius="0em">
                    <span :style="{borderBottom: menu[0].chosen ? 'solid 4px rgb(209, 89, 89)' : 'solid 4px transparent'}">EN COURS</span>
                </AndroidButton>
                
                <AndroidButton :style="{color: menu[1].chosen ? 'rgb(60, 94, 189)' : 'gray'}"
                @clicked="changeMenu(1)"
                class="ticketsButton" radius="0em">
                    <span :style="{borderBottom: menu[1].chosen ? 'solid 4px rgb(209, 89, 89)' : 'solid 4px transparent'}">EXPIRÉ</span>
                </AndroidButton>
            </div>
        </div>

        <div class="ticketsList">
            <div v-show="ticketsList.length == 0"> Aucun ticket </div>
            <AndroidButton v-for="(tk,index) in ticketsList" :key="index" 
            @clicked="showTicket(tk)" class="ticketsBEltC" radius="0.2em">
                <div class="ticketsBElt">
                    <div :style="{backgroundColor:$store.state.classColor[tk.classe]}" class="ticketsBadge">
                        {{ tk.classe }}
                    </div>
                    <div>
                        <p> {{ tk.depart + ' vers ' + tk.arrivee }}</p>
                        <div> Départ à {{ tk.heureDepart }} </div>
                        <span> {{ tk.prixTicket*tk.nombre }} fcfa {{ ' ('+tk.nombre+' ticket(s) )' }} </span>
                    </div>
                    <div class="ticketsBuy">
                        <span v-show="tk.voyageState == 2" style="background-color:rgb(249, 99, 99)"> 
                            {{ tk.validated ==0 ?  'Voyage annulé' : 'Remboursé' }}
                        </span>
                        <span :style="{backgroundColor:(tk.validated ==0 && tk.difference > -1) ? 'rgb(89, 180, 143)':'rgb(209, 89, 89)' }"> 
                            {{tk.jour+' '+this.$store.state.mois[tk.mois-1]+' '+tk.annee}}
                        </span>
                    </div>
                </div>
            </AndroidButton>
        </div>

    <AndroidButton v-show="false" @clicked="getInfos" radius="5em" class="ticketsPlus"><img src="./img/reload.svg"/></AndroidButton>
    <LLoading1 :visible="loading"/>
    <Reload @reload="getInfos" :persistant="true" ref="reload" />
    </div>
</template>
<style src="./tickets.css">
</style>
<script src = "./tickets.js">
</script>
