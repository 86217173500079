<template>
    <div class ="buttonI0" href="google.com">
        {{ message }}
    </div>
</template>
<style>
    .buttonI0 {
        box-sizing:border-box;
        padding: 10px;
        width: 320px;
        background-color:var(--mainColor);
        border-radius: 0.4em;
        border: none;
        color: white;
        font-size: 15px;
        font-weight: bold;
        cursor: pointer;
    }

  /*----- responsive managment zone... ------------------ */
    @media screen and (max-width: 440px) {
        .buttonI0 {
            width: 90%;
        }
    }
</style>
<script>
export default {
  name: 'ButtonI0',
  props: {
    message: String
  }
}
</script>
