<template>
    <div class="HButton" :style="{animation:blink}" @click="clicked(this)">
      Choisissez un trajet
    </div> 
</template>
<style>
@keyframes blink {
  from {
    background-color: rgba(128, 128, 128, 0.7);
  }
  to {
    background-color: transparent;
  }
}
.HButton {
    width: 230px;
    height: 50px;
    color:white;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px -1px 1px 1px rgba(255, 255, 255, 0.3);
    display: flex;justify-content: center;align-items:center ;
    padding-left: 20px;
    padding-right: 20px;
    border-radius:0.1em;
    display: flex;justify-content: center;align-items: center;
    cursor:pointer;
}
</style>
<script>
  export default {
  name: 'HButton',
  data () {
      return {
          blink:false
      }
  },
  emits:["clicked"],
  methods: {
      clicked: async (k)=>  {
        function waitDelay(t){
            return new Promise((resolve) => {
                setTimeout(resolve, t);
            })
        }
        k.blink = 'none'
        await waitDelay(50)
        k.blink = 'blink 0.5s 1 ease-out'
        await waitDelay(300)
        k.$emit('clicked')
      }
  }
}
</script>
