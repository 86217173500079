import AndroidButton from '@/components/AndroidButton'
export default {
  name: 'lLoading1',
  props: {
    persistant: {
      type: Boolean,
      default:false
    }
  },
  components: {
    AndroidButton
  },
  emits:["reload"],
  data () {
    return {
      Display:false
    }
  },
  methods: {
    reloading () {
      this.$emit('reload')
      this.Display = false
    },
    show () {
      this.Display = true
    },
    closing(event) {
      if(event.target == this.$refs.main && this.persistant) this.Display = false
    }
  }
}
