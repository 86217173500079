import { createApp } from 'vue'
import App1 from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { App } from '@capacitor/app'

App.addListener('backButton', ({ canGoBack }) => {
    if(canGoBack){
      window.history.back()
    } else {
      App.exitApp()
    }
  })

  axios.defaults.withCredentials = true
  //--
createApp(App1).mixin({
  methods: {
    ExitCapacitorApp : function () {
      App.exitApp()
    },
  },
}).use(store).use(router).use(VueAxios, axios).mount('#app')