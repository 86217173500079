<template>
    <AndroidButton @clicked="$emit('clicked')">
      <svg
         class="footerUser"
         fill="#000000"
         width="676.74908"
         height="783.10449"
         viewBox="0 0 40.604945 46.986269"
         data-name="Layer 1"
         id="Layer_1"
         version="1.1"
         sodipodi:docname="account-user-avatar-svgrepo-com.svg"
         inkscape:version="1.1 (c4e8f9e, 2021-05-24)"
         xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
         xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:svg="http://www.w3.org/2000/svg">
         <defs
         id="defs11" />
         <title
         id="title2" />
         <path
         :fill="selected ? 'rgba(63, 69, 146)':'black'"
         style="opacity:1;fill-opacity:0.956551;stroke-linejoin:round;stroke-miterlimit:0;stroke-opacity:1"
         d="m 19.08468,19.823794 c -3.699075,-0.42434 -6.912478,-3.054824 -8.187006,-6.701863 -0.356676,-1.020617 -0.395713,-1.318678 -0.398288,-3.04097 -0.0025,-1.6254745 0.04585,-2.0548755 0.324735,-2.8873185 1.091764,-3.258987 3.224785,-5.416321 6.461553,-6.53520801 1.010034,-0.349148 1.328951,-0.391145 2.97025,-0.391145 1.641302,0 1.96022,0.042 2.970251,0.391145 2.793454,0.96564201 4.770397,2.71956601 5.972241,5.29851301 0.688542,1.477491 0.926909,2.751408 0.851056,4.5483345 -0.241188,5.713611 -5.253833,9.973647 -10.964792,9.318512 z"
         id="path865" />
         <path
         :fill="selected ? 'rgba(63, 69, 146)':'black'"
         style="opacity:1;fill-opacity:0.956551;stroke-linejoin:round;stroke-miterlimit:0;stroke-opacity:1"
         d="m 0.20866001,43.329091 c 0,-4.660591 0.2014555,-6.541913 0.96916869,-9.050735 C 1.629848,32.801196 2.8567359,30.244617 3.7238999,28.972873 5.071808,26.996092 6.9982552,25.189628 8.9301292,24.0909 10.013595,23.474694 10.880733,23.118549 12.189752,22.752129 l 1.074537,-0.300784 6.55468,-0.04056 c 5.699239,-0.03529 6.693175,-0.01625 7.615897,0.145771 6.810025,1.195779 12.085578,7.854704 12.85202,16.222126 0.05715,0.624031 0.105182,2.679072 0.1067,4.566764 l 0.0027,3.432163 H 20.302509 0.20866001 Z"
         id="path2505" />
      </svg>
    
    </AndroidButton>
</template>
<style>
.footerUser {
    width: 27px;
    height: 27px;
}
</style>
<script>
import AndroidButton from '@/components/AndroidButton'
  
  export default {
  name: 'User',
  props: {
      selected: {
          type: Boolean,
          default:false
      }
  },
  emits:["clicked"],
  components: {
    AndroidButton      
  }
}
</script>
