import HButton from './HButton'
import AndroidButton from '@/components/AndroidButton'

export default {
  name: 'landing',
  
  components: {
    HButton,
    AndroidButton  
  },
  data () {
    return {
      logo:this.$store.state.baseUrl+'images/'+this.$store.state.currentAgency.logo,
      wallpaper:this.$store.state.baseUrl+'images/'+this.$store.state.currentAgency.wallpaper
    }
  },
  methods: {
    addView () {
      this.axios.post(this.$store.state.baseUrl + 'user.php', {
        type:'addView',
        nomAgence: this.$store.state.currentAgency.nom
      })
      .then(() => {
        
      })
      .catch((error) => {
        console.log(error)
      })
    }
  },
  mounted () {
    this.addView()
  },
  beforeRouteEnter(to, from) {
    if(typeof from.name == 'undefined') return {name:'start'}
  }
}
